.about-section {
    padding: 40px 20px;
    margin: 20px auto;
    max-width: 900px;
    background: linear-gradient(135deg, #93b1cb,  #019d9d);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.125);
}

.about-content {
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
    
}

.about-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin-right: 20px;
}

.about-content p {
    margin: 0;
    font-size: 1.1em;
    line-height: 1.6;
    color: white;
}
