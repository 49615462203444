/* Base styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background: linear-gradient(135deg, #f0f4f8, #f6ece7);
  color: #333;
}

/* Responsive grid layout */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

/* Responsive typography */
h2 {
  font-size: 2rem;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  h2 {
      font-size: 1.5rem;
  }

  .project-card {
      padding: 15px;
  }
}

@media (max-width: 480px) {
  h2 {
      font-size: 1.2rem;
  }

  .project-card {
      padding: 10px;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.cta-button {
  background-color: #008080;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.cta-button:hover {
  background-color: #005f5f;
}


