/* src/components/Skills.css */
.skills-section {
    padding: 40px 20px;
    margin: 20px auto;
    max-width: 900px;
    background-color: #ffefc1;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.skills-section h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #008080;
}

.skills-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.skill {
    background-color: #fafafa;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.skill:hover {
    transform: scale(1.05);
}

.skill h3 {
    margin-bottom: 10px;
    color: #005f5f;
}

.skill p {
    color: #333;
}
