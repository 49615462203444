
.footer {
    background-color: #2c2c2c;
    color: #ffffff; 
    padding: 20px 0;
    text-align: center;
    margin-top: 50px;
    position: relative;
}

.social-links {
    margin-bottom: 10px;
}

.social-icon {
    width: 40px;
    height: 40px;
    margin: 0 10px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0.8; 
    border-radius: 50%; 
    background-color: white; 
    padding: 5px; 
}

.social-icon:hover {
    transform: scale(1.1);
    opacity: 1; 
}

.footer p {
    margin: 10px 0 0;
    font-size: 14px;
    color: #bbb; /* Lighter gray for the text */
}
