/* src/components/Header.css */
.header {
    background: linear-gradient(135deg, #93b1cb,  #019d9d);
    color: white;
    padding: 20px;
    text-align: center;
}

.header nav a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
    font-weight: bold;
}

.header nav a:hover {
    text-decoration: underline;
}
