
.projects-section {
    padding: 40px 20px;
    margin: 20px auto;
    max-width: 1200px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.projects-section h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #008080;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
}

.project-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    background-color: #ffefc1; /* Subtle color change on hover */
}

.project-image {
    width: 100%;
    height: 180px; /* Set a consistent height for all images */
    object-fit: cover; /* Ensures the image covers the area without distortion */
    border-radius: 8px;
    margin-bottom: 15px;
    
}

.project-content {
    flex-grow: 1;
}

.project-link {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #008080;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    align-self: center;
}

.project-link:hover {
    background-color: #005f5f;
}

@media (max-width: 768px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }
}

